.column-module {

    &.text-Centre {
        text-align: center;
    }

    &.full-width {
        grid-column: 1 / -1;
    }

    h2 :is(sub, sup) {
        font-size: 2rem;
    }

    .buttons, 
	.buttons .block-editor-inner-blocks > div {
		display: flex;
		justify-content: center;
		gap: 1em;
		flex-wrap: wrap;
	}

    &.text-Left {
        .buttons {
            justify-content: flex-start;
        }
    }

    img {
        width: 100%;
        &.half-width {
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .podcast-wrapper iframe {
        width: 100%;
    }

    .video-wrapper {

        width: 100%;

        iframe {
            aspect-ratio: 16 / 9;
            height: auto;
        }

    }


    &.video-embed {
        display: flex;
        align-items: center;

        .video-wrapper {

            width: 100%;

            iframe {
                aspect-ratio: 16 / 9;
                height: auto;
            }

        }

    }

    .table-block {
        
        .bnpp-container {
            width: 100%!important;
            padding: 0;
        }
    }

}